import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { Text } from "../Text";
import { pxToRem } from "../../utils/px-to-rem";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import {
    enterTextViaScrambling,
    enterTextViaScramblingWithoutBreakingChars,
    fadeInText
} from "../../utils/text-animations";

const IMAGE_ASPECT_RATIO = 2944 / 2911;
interface IFfTwitterPoll {
    onComplete?: () => void;
}

export const FfTwitterPoll = ({ onComplete }: IFfTwitterPoll) => {
    const mainRef = useRef(null);

    let mainTl = null;
    gsap.registerPlugin(ScrambleTextPlugin);

    const [imageWidth, setImageWidth] = React.useState(0);

    let imageWidthSetter = null;
    useLayoutEffect(() => {
        imageWidthSetter = () => {
            const height = document.getElementById(
                "ff-poll-pie-container"
            ).offsetHeight;
            setImageWidth(height * IMAGE_ASPECT_RATIO);
        };
        imageWidthSetter();

        window.addEventListener("resize", imageWidthSetter);

        return () => {
            window.removeEventListener("resize", imageWidthSetter);
        };
    }, []);

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            mainTl = gsap
                .timeline({ paused: false, delay: 0 })
                .add(
                    fadeInText(
                        gsap.utils.toArray("#ff-twitter-poll-title")
                    ).play()
                )
                .fromTo(
                    "#ff-twitter-poll-caption .line-1",
                    {
                        clipPath: "inset(0% 100% 0% 0%)"
                    },
                    {
                        duration: 2,
                        clipPath: "inset(0% 0% 0% 0%)",

                        ease: "none"
                    }
                )
                .fromTo(
                    "#ff-twitter-poll-caption .line-2",
                    {
                        clipPath: "inset(0% 100% 0% 0%)"
                    },
                    {
                        duration: 2,
                        clipPath: "inset(0% 0% 0% 0%)",

                        ease: "none"
                    }
                )
                // .add(
                //     enterTextViaScramblingWithoutBreakingChars(
                //         gsap.utils.toArray("#ff-twitter-poll-caption")
                //         // "words,chars,lines"
                //     ).play()
                // )
                .from("img", {
                    autoAlpha: 0
                })
                .to(".percent-text.first", {
                    duration: 0.75,
                    scrambleText: {
                        text: "46.9%",
                        chars: "lowerCase",
                        revealDelay: 0,
                        tweenLength: false
                    }
                })
                .to(".percent-text.second", {
                    duration: 0.75,
                    scrambleText: {
                        text: "33.7%",
                        chars: "lowerCase",
                        revealDelay: 0,
                        tweenLength: false
                    }
                })
                .to(".percent-text.third", {
                    duration: 0.75,
                    scrambleText: {
                        text: "19.4%",
                        chars: "lowerCase",
                        revealDelay: 0,
                        tweenLength: false
                    }
                })
                .then(() => {
                    onComplete();
                });
        }, mainRef);

        return () => ctx.revert();
    }, []);

    const HORIZONTAL_PADDING = 20;
    const VERTICAL_PADDING = 20;

    return (
        <div
            ref={mainRef}
            css={{
                display: "grid",
                height: `calc(100vh - ${pxToRem(VERTICAL_PADDING * 2)})`,
                width: `calc(100vw - ${pxToRem(HORIZONTAL_PADDING * 2)})`,
                padding: `${pxToRem(VERTICAL_PADDING)} ${pxToRem(
                    HORIZONTAL_PADDING
                )}`,
                gridTemplateRows: "auto 1fr",
                overflow: "scroll"
            }}
        >
            <div
                className="texts"
                css={{
                    display: "grid",
                    gridGap: pxToRem(18),
                    textAlign: "center",
                    position: "relative",
                    zIndex: 2
                }}
            >
                <Text id="ff-twitter-poll-title" variant="title" color="white">
                    PAKISTAN’S NATIONAL SWEET
                </Text>
                <Text
                    id="ff-twitter-poll-caption"
                    variant="caption"
                    color="white"
                    css={{
                        maxWidth: pxToRem(700),
                        margin: "auto",
                        textAlign: "center",
                        ".line": {
                            // display: "inline !important"
                        },
                        ".line:nth-child(2)": {
                            // textDecoration: "underline"
                        }
                    }}
                >
                    <div className="line line-1">
                        Gulab Jamun was elected as{" "}
                        <span>
                            <a
                                css={{
                                    display: "inline",
                                    textDecoration: "underline !important",
                                    "& .char": {
                                        textDecoration: "underline"
                                    }
                                }}
                                href="https://www.indiatimes.com/trending/social-relevance/pakistan-elects-gulab-jamun-as-its-national-sweet-but-people-aren-t-too-happy-about-it-360029.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                PAKISTAN’S national SWEET
                            </a>
                        </span>
                    </div>
                    <div className="line line-2">
                        <span>
                            <a
                                css={{
                                    display: "inline",
                                    textDecoration: "underline !important",
                                    "& .char": {
                                        textDecoration: "underline"
                                    }
                                }}
                                href="https://www.indiatimes.com/trending/social-relevance/pakistan-elects-gulab-jamun-as-its-national-sweet-but-people-aren-t-too-happy-about-it-360029.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                IN A Twitter poll
                            </a>
                        </span>{" "}
                        BEATING Barfi and jalebi.
                    </div>
                </Text>
            </div>
            <div css={{ position: "sticky" }}>
                <div
                    id="ff-poll-pie-container"
                    css={{
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <div css={{ position: "relative", height: "100%" }}>
                        <Text
                            css={{
                                zIndex: 2,
                                position: "absolute",
                                top: "40%",
                                left: "8%",
                                transform: "scale(46.9)"
                            }}
                            className="percent-text first"
                            variant="ffPollPieText"
                            color="rgba(255, 255, 255, 0.3)"
                        ></Text>
                        <Text
                            css={{
                                zIndex: 2,
                                position: "absolute",
                                right: "10%",
                                top: "50%",
                                transform: "scale(33.7)"
                            }}
                            className="percent-text second"
                            variant="ffPollPieText"
                            color="rgba(255, 255, 255, 0.3)"
                        ></Text>
                        <Text
                            css={{
                                zIndex: 2,
                                position: "absolute",
                                bottom: "2%",
                                left: "40%",
                                transform: "scale(19.4)"
                            }}
                            className="percent-text third"
                            variant="ffPollPieText"
                            color="rgba(255, 255, 255, 0.3)"
                        ></Text>
                        <img
                            css={{
                                width: imageWidth,
                                transform: "scale(1.2)",
                                zIndex: -1
                            }}
                            src="/images/ff-poll-pie.png"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
